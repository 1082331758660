<template>
  <div>
    <!-- <h1>{{ this.$t('Dashboards') + ' TBBM' }}</h1> -->
    <BCardActions
      title="Permintaan Pelayanan Kapal & Barang"
      sub-title="At [JETTY 3A], every crew member's effort makes a difference. Together, we achieve great things."
      action-collapse
      class="mt-1"
      :collapsed="false"
      :no-actions="true"
    >
      <YoSalTable />
    </BCardActions>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import YoSalTable from '@views/pages/monitoring/jalur-sungai/tbbm-j3a/YoSalTable.vue'

export default {
  components: {
    BCardActions,
    YoSalTable
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  }
}
</script>
