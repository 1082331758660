var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BCardActions', {
    staticClass: "mt-1",
    attrs: {
      "title": "Permintaan Pelayanan Kapal & Barang",
      "sub-title": "At [JETTY 3A], every crew member's effort makes a difference. Together, we achieve great things.",
      "action-collapse": "",
      "collapsed": false,
      "no-actions": true
    }
  }, [_c('YoSalTable')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }